/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Fullmap, FullmapWrap, Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, ContactForm, FullmapCover } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"} description={"Kontaktní telefon, email, adresa"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Fullmap className="--style2 mt--06" name={"6mknk5u6qtd"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

          <FullmapWrap className="--center pb--20 pl--40 pr--40 pt--20" style={{"maxWidth":1971,"marginBottom":0}}>
          </FullmapWrap>

        </Fullmap>


        <Column className="pb--50 pt--50" name={"kontakt"} style={{"marginBottom":0}}>
          
          <ColumnWrap className="column__flex --left el--2 flex--top" style={{"maxWidth":1352,"marginTop":0,"paddingTop":0}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Hlavní 68, Frýdlant nad Ostravicí<br>"}>
              </Title>

              <Text className="text-box" content={"Pondělí - Pátek:"}>
              </Text>

              <Subtitle className="subtitle-box mt--0" content={"6:00 - 18:00"}>
              </Subtitle>

              <Text className="text-box" content={"Víkend:"}>
              </Text>

              <Subtitle className="subtitle-box mt--0" content={"---<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <ContactForm className="--shape2 --style3" style={{"maxWidth":644}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"number","placeholder":"Telefon + 420 "},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text poptávky"},{"name":"Odeslat","type":"submit","align":"right"}]}>
              </ContactForm>

              <Text className="text-box" content={"Dotazy volejte na +420 777 707 360<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap name={"ptmvhrl5w3e"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"16"} place={"Frýdlant nad Ostravicí Hlavní 68"} style={{"marginBottom":20}}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Fullmap >
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Fullmap className="--style2" name={"zcvluh6uct8"}>
          
          <FullmapWrap className="--justify pb--20 pt--20">
          </FullmapWrap>

          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column className="--border4 pb--60 pt--60" style={{"backgroundColor":"var(--color-supplementary)"}} border={"4"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Výsadba, poradenství<br>&amp; řez ovocných stromků<br></span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Hlavní 68, Frýdlant nad Ostravicí</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"+420 777 707 360<br>info@uzitecnazahrada.cz"}>
              </Text>

              <Text className="text-box" content={"IČ: 26787792<br>Vytvořeno přes <a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}